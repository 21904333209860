@use "../../styles/colors.scss" as c;

.kpi-card-icon {
	font-size: 30px;
	margin-top: -3px;
	color: c.$textSecondary;
}

.transform45 {
	transform: rotate(45deg);
}
