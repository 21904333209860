.disable-menu-hover {
	&:hover {
		background-color: white;
		-webkit-tap-highlight-color: white;
	}
}

.clear-icon {
	background: rgba(#374151, 0.3);
	border-radius: 50%;
	height: 16px;
	width: 16px;
	transition: 0.1s;

	&:hover {
		background: rgba(#374151, 0.5);
	}
}
