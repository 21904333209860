.swiper {
	width: 80vw;
}

.swiper-slide {
	display: flex;
	margin-bottom: 3rem;
	justify-content: center;
	height: auto !important; /* Have to have !important since the package's class tries to override */
}
