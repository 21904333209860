@use "../../styles/colors.scss" as c;

#expanded-table-parent {
	th {
		text-transform: capitalize;
	}

	th,
	td {
		color: c.$textSecondary;
		border-bottom-color: rgba(c.$textSecondary, 0.1);
	}

	tr {
		border-bottom: 1px solid rgba(c.$textSecondary, 0.075);
		height: 48.5px;
		max-height: 48.5px;
	}

	td {
		border-collapse: collapse;
	}

	tr:last-child {
		border-bottom: none;
	}
}

.expandable-table-cell {
	min-width: 100px;
	max-width: 120px;
	border: none;
	font-size: 14px;
	line-height: 18px;
	height: 53px;
}

.name-table-container {
	order: 0;
	flex: 1 1 auto;
	min-width: 275px;
}

.center-table-container {
	order: 1;
	flex: 5 1 auto;
	overflow-x: auto;
	box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
	border-width: 0px 1px;
	border-style: solid;
}

.total-table-container {
	order: 2;
	flex: 1 1 auto;
}

.sort-icon-style {
	height: 12px;
	position: absolute;
	right: 0;
	margin: auto 0;
	top: 0;
	bottom: 0;
}
