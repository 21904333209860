@for $i from 0 through 100 {
	.w-#{$i}-percent {
		width: $i * 1%;
	}

	.h-#{$i}-percent {
		height: $i * 1%;
	}

	.w-#{$i}-px {
		width: $i * 1px;
	}

	.h-#{$i}-px {
		height: $i * 1px;
	}
}

@for $i from 0 through 1000 {
	.min-w-#{$i}-px {
		min-width: $i * 1px;
	}

	.min-h-#{$i}-px {
		min-height: $i * 1px;
	}

	.max-w-#{$i}-px {
		max-width: $i * 1px;
	}

	.max-h-#{$i}-px {
		max-height: $i * 1px;
	}
}
