@import "./variables";

@each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
	@for $i from 0 through $spacing-max-multiple {
		.m-#{$abbr}-#{$i}-theme {
			margin-#{$name}: $theme-spacing * $i;
		}

		.p-#{$abbr}-#{$i}-theme {
			padding-#{$name}: $theme-spacing * $i;
		}
	}

	@for $i from 0 through 200 {
		.m-#{$abbr}-#{$i} {
			margin-#{$name}: 1px * $i;
		}

		.p-#{$abbr}-#{$i} {
			padding-#{$name}: 1px * $i;
		}
	}
}

@for $i from 0 through $spacing-max-multiple {
	.m-#{$i}-theme {
		margin: $theme-spacing * $i;
	}

	.m-tb-#{$i}-theme {
		margin-top: $theme-spacing * $i;
		margin-bottom: $theme-spacing * $i;
	}

	.m-lr-#{$i}-theme {
		margin-left: $theme-spacing * $i;
		margin-right: $theme-spacing * $i;
	}

	.p-#{$i}-theme {
		padding: $theme-spacing * $i;
	}

	.p-tb-#{$i}-theme {
		padding-top: $theme-spacing * $i;
		padding-bottom: $theme-spacing * $i;
	}

	.p-lr-#{$i}-theme {
		padding-left: $theme-spacing * $i;
		padding-right: $theme-spacing * $i;
	}

	.m-#{$i} {
		margin: 1px * $i;
	}

	.m-tb-#{$i} {
		margin-top: 1px * $i;
		margin-bottom: 1px * $i;
	}

	.m-lr-#{$i} {
		margin-left: 1px * $i;
		margin-right: 1px * $i;
	}

	.p-#{$i} {
		padding: 1px * $i;
	}

	.p-tb-#{$i} {
		padding-top: 1px * $i;
		padding-bottom: 1px * $i;
	}

	.p-lr-#{$i} {
		padding-left: 1px * $i;
		padding-right: 1px * $i;
	}

	.t-#{$i} {
		top: 1px * $i;
	}
}
