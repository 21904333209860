.highcharts-drilldown-data-label text {
	text-decoration: none;
}

.highcharts-xaxis-labels text {
	text-decoration: none;
}

.highcharts-container,
.highcharts-container svg {
	width: 100%;
}
