$displayProps: ("block", "inline-block", "inline", "flex", "grid", "inline-flex", "inline-grid", "table", "inline-table", "list-item", "none", "contents");
@each $display in $displayProps {
	.display-#{$display} {
		display: #{$display};
	}
}

$positionProps: ("static", "relative", "absolute", "fixed", "sticky", "inherit", "initial", "revert", "revert-layer", "unset");
@each $position in $positionProps {
	.position-#{$position} {
		position: #{$position};
	}
}

$overflowProps: ("visible", "hidden", "scroll", "auto");
@each $overflow in $overflowProps {
	.overflow-#{$overflow} {
		overflow: #{$overflow};
	}
}

$floatProps: ("left", "right");
@each $float in $floatProps {
	.float-#{$float} {
		float: #{$float};
	}
}

@for $i from 0 through 10 {
	.z-index-#{$i * 10} {
		z-index: 10 * $i;
	}
}

@each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
	@for $i from 0 through 100 {
		.#{$abbr}-#{$i} {
			#{$name}: $i * 1px;
		}

		.#{$abbr}--#{$i} {
			#{$name}: $i * -1px;
		}
	}
}

.vertical-align-bottom {
	vertical-align: text-bottom;
}