@import "./colors";
@import "./spacing";
@import "./fonts";
@import "./flexbox";
@import "./positioning";
@import "./cursors";
@import "./borders";
@import "./size";
@import "./buttons";
@import "./animation";

.main-grid {
	display: grid;
	position: relative;
	grid-template-columns: 270px calc(100vw - 270px);
	max-width: 100vw;
	overflow-x: hidden;
	max-height: 100vh;
}

.sidebar-col {
	height: fit-content;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.main-col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
