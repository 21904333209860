/* Borders */
@each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
	@for $i from 0 through 5 {
		.border-#{$abbr}-#{$i} {
			border-#{$name}: $i * 1px solid $borderMain;
		}
	}
}

@for $i from 0 through 5 {
	.border-#{$i} {
		border: $i * 1px solid $borderMain;
	}
}

@for $i from 0 through 10 {
	.border-radius-#{$i} {
		border-radius: $i * 1px;
	}
}

/* Border styles */
.divider-border {
	border-bottom: 1px solid $borderMain;
}

.border-col-light {
	border-color: $borderMain;
}
