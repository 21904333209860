/* Skyblue */
$skyBlueMain: #3a9acd;
$skyBlueSecondary: #5eb0ef;
$skyBlueTertiary: #00174d;
$skyBlueMid: #4d90cd;

/* Backgrounds */
$whiteMain: #ffffff;
$whiteSecondary: #f5f5f5;
$whiteTertiary: #f4f7ff;
$greyBackground: #e5e7eb;

/* Texts */
$textMain: #374151;
$textSecondary: #637381;
$borderMain: #f0f0f0;

:export {
	skyBlueMain: $skyBlueMain;
	skyBlueSecondary: $skyBlueSecondary;
	skyBlueTertiary: $skyBlueTertiary;
	skyBlueMid: $skyBlueMid;
	whiteMain: $whiteMain;
	whiteSecondary: $whiteSecondary;
	whiteTertiary: $whiteTertiary;
	textMain: $textMain;
	textSecondary: $textSecondary;
	borderMain: $borderMain;
}

@for $i from 1 through 100 {
	.opacity-#{$i} {
		opacity: $i * 0.01;
	}
}

/* Backgrounds */
.bg-white {
	background-color: $whiteMain;
}

.bg-secondary {
	background-color: $textSecondary;
}

.bg-hover-grey:hover {
	background-color: rgba($whiteSecondary, 0.6);
}

.bg-grey {
	background-color: $greyBackground;
}
