.project-content {
	margin-top: 0;
}

.project-content + p {
	margin-bottom: 0;
}

.swiper {
	overflow: visible;
}

.swiper-slide {
	margin-bottom: 1rem;
}
