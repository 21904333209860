.loaderContainer {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.loader {
	width: 40px;
	height: 40px;
	display: inline-block;
	position: relative;
	border-radius: 50%;
	background: #5ccbf2;
	background: linear-gradient(to right, #5ccbf2 70%, #4c91ce 92%);
	position: relative;
	animation: spinner-rotation 1.4s infinite linear;
}

.loader:before {
	width: 50%;
	height: 50%;
	background: #5ccbf2;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
}

.loader:after {
	background: #ffffff;
	width: 60%;
	height: 60%;
	border-radius: 50%;
	content: "";
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.errorMessage {
	margin-bottom: 10px;
}

@-webkit-keyframes spinner-rotation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner-rotation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
