@import "./colors";

.button-primary {
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	border: none;
	text-transform: none;
	color: white;
	background: $skyBlueSecondary;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);

	&:hover {
		background: rgba(0, 0, 0, 0.15);
	}
}

.button-secondary {
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	border: none;
	text-transform: none;
	color: $skyBlueSecondary;
	background: white;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);

	&:hover {
		background: rgba(0, 0, 0, 0.15);
	}

	span.MuiCircularProgress-root {
		color: $skyBlueSecondary;
		width: 25px !important;
		height: 25px !important;
	}
}
