@use "../../styles/colors.scss" as colors;
@use "../../styles/variables.scss" as variables;

.sidebar-icon {
	min-width: 0;
	margin-right: variables.$theme-spacing;
	margin-bottom: 2px;
	justify-content: initial;
	color: colors.$textSecondary;

	svg {
		width: 18px;
		height: 18px;
	}
}

.sidebar-button {
	min-height: 48px;
	justify-content: initial;
	padding-left: variables.$theme-spacing * 4;
}

.sidebar-avatar-container {
	justify-content: center;
	padding: 0 variables.$theme-spacing * 4;
	min-height: 48px;
	margin-top: 20px;
	display: block;
}
