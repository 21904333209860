$endColor: rgb(58, 154, 205);

.picker-day-between {
	background-color: rgba(92, 203, 242, 0.4);
	border-radius: 0;
	margin: 0;
	width: 40px;
	color: black;
}

.picker-day-between-first {
	border-radius: 50% 0px 0px 50%;
	background-color: $endColor !important;
	color: black;
}

.picker-day-between-last {
	border-radius: 0px 50% 50% 0px;
	background-color: $endColor !important;
	color: black;
}

.picker-day-between-last:hover {
	background-color: $endColor;
	color: black;
}
