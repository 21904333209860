@import "./colors";

$semiBold: "Gilroy-Semibold";
$bold: "Gilroy-Bold";
$regular: "Gilroy-Regular";
$medium: "Gilroy-Medium";
$light: "Gilroy-Light";

$fonts: (
	"semi-bold": $semiBold,
	"bold": $bold,
	"regular": $regular,
	"medium": $medium,
	"light": $light,
);

@each $key, $font in $fonts {
	.font-#{$key} {
		font-family: #{$font};
	}
}

@for $i from 0 through 30 {
	.font-size-#{$i} {
		font-size: 1px * $i;
	}
}

/* Font Color */

.txt-main {
	color: $textMain;
}

.txt-secondary {
	color: $textSecondary;
}

.txt-white {
	color: $whiteMain;
}

.txt-blue {
	color: $skyBlueSecondary;
}

/* Font Style */
.txt-decoration-none {
	text-decoration: none;
}

.txt-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

$textTransforms: ("none", "lowercase", "uppercase", "capitalize");

@each $textTransform in $textTransforms {
	.txt-transform-#{$textTransform} {
		text-transform: #{$textTransform};
	}
}

.txt-transform-capitalize-first::first-letter {
	text-transform: capitalize;
}

$textAlignProps: ("center", "left", "right");
@each $textAlign in $textAlignProps {
	.txt-align-#{$textAlign} {
		text-align: #{$textAlign};
	}
}

.highcharts-title {
	font-weight: 300;
	color: $textMain;
}
