@keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-o-keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-moz-keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.animate-fade-in-out {
	-webkit-animation: fadeInOut 3s ease-in-out;
	-moz-animation: fadeInOut 3s ease-in-out;
	-o-animation: fadeInOut 3s ease-in-out;
	animation: fadeInOut 3s ease-in-out;
}
