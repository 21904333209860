@font-face {
	font-family: "Gilroy-Light";
	src: url("./fonts/Gilroy-Light.otf");
}

@font-face {
	font-family: "Gilroy-SemiBold";
	src: url("./fonts/Gilroy-SemiBold.otf");
}

@font-face {
	font-family: "Gilroy-Regular";
	src: url("./fonts/Gilroy-Regular.otf");
}

@font-face {
	font-family: "Gilroy-Bold";
	src: url("./fonts/Gilroy-Bold.otf");
}

@font-face {
	font-family: "Gilroy-Medium";
	src: url("./fonts/Gilroy-Medium.otf");
}

svg,
text,
table,
thead {
	font-family: "Gilroy-SemiBold";
}

text.capitalize {
	text-transform: capitalize;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:-webkit-any-link {
	color: inherit;
}

.article {
	margin-top: -24px;
}

.socialMediaIcons a svg {
	color: white;
}
